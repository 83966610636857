<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="width"
    height="19.759"
    viewBox="0 0 25 19.759">
    <rect
      width="4.931"
      height="14.793"
      rx="2.466"
      transform="translate(0 6.947) rotate(-30)"
      fill="#feb421" />
    <rect
      width="4.931"
      height="23.012"
      rx="2.466"
      transform="translate(21.513) rotate(45)"
      fill="#feb421" />
  </svg>
</template>
