<template>
	<span
		class="relative inline-flex items-center rounded-full py-1 px-2 text-xs max-h-6 w-max truncate ..."
		:class="[colors, pl]">
		{{ convertedLabel }}

		<component
			v-if="icon"
			:is="icon"
			class="absolute left-0 w-5 pl-2"></component>

		<button
			v-if="!button"
			type="button"
			class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-white hover:bg-white hover:text-indigo-500 focus:bg-indigo-500 focus:text-white focus:outline-none">
			<svg
				class="h-2 w-2"
				stroke="currentColor"
				fill="none"
				viewBox="0 0 8 8">
				<path
					stroke-linecap="round"
					stroke-width="1.5"
					d="M1 1l6 6m0-6L1 7" />
			</svg>
		</button>
	</span>
</template>

<script setup>
	import {
		sentenceCase,
		camelCaseToSpaces,
	} from "@/utils/helpers";

	const props = defineProps({
		label: {
			type: [String, Object, Number],
			required: true,
		},
		button: {
			type: Boolean,
			default: true,
		},
		bgColorProfile: {
			type: String,
			default: "",
		},
		type: {
			type: String,
		},
	});

	// Icon
	const dateIcon = resolveComponent("SVGReschedule");
	const publishedIcon = resolveComponent("SVGAccept");

	const icon = computed(() => {
		switch (props.type) {
			case "date":
				return dateIcon;
			case "published":
				return publishedIcon;
			default:
				return null;
		}
	});

	const convertedLabel = computed(() => {
		const label = ref("");

		if (
			props.type === "date" ||
			props.type === "published"
		) {
			//Return as '26 Apr' format
			label.value = new Date(
				props.label
			).toLocaleDateString("en-GB", {
				day: "numeric",
				month: "short",
			});
		} else if (props.type === "dateAndTime") {
			//Return as 'Wed 26 Apr 2021 at 12:00' format using useDateFormat
			const date = useDateFormat(
				props.label,
				"ddd D MMM YYYY"
			);

			const time = useDateFormat(props.label, "HH:mm");

			label.value = `${date.value} at ${time.value}`;
		} else {
			label.value = props.label;
		}

		//Check if ref
		if (label.value.value) {
			return sentenceCase(
				camelCaseToSpaces(label.value.value)
			);
		} else {
			return sentenceCase(camelCaseToSpaces(label.value));
		}
	});

	//Get color profile from props. If matches a color profile, use that color profile. Otherwise, use the default color profile.
	const colors = computed(() => {
		const colorProfiles = {
			wow: "bg-gradient-to-tr from-too-gold to-golden-brown text-voir-blue font-medium shadow-xl",
			muted:
				"border border-1 border-yellow-600 text-yellow-600 font-medium",
			mutedAlt:
				"border border-1 border-voir-blue/10 text-voir-blue/60 font-medium",
			noBg: "border border-1 border-voir-blue/10 text-voir-blue/60 font-medium",
			amended:
				"bg-breakwater text-voir-blue font-medium shadow-xl",
			read: "bg-breakwater text-voir-blue font-medium shadow-xl",
			created:
				"bg-improbable/40 text-voir-blue font-medium shadow-xl",
			published:
				"bg-too-gold text-voir-blue font-medium shadow-xl",
			publishedOutline:
				"border border-2 border-too-gold text-too-gold font-medium shadow-sm",
			acceptedByAdmin:
				"bg-golden-brown text-voir-blue font-medium shadow-xl",
			acceptedByUser:
				"bg-golden-brown text-voir-blue font-medium shadow-xl",
			scheduled:
				"bg-commandes text-white font-medium shadow-xl",
			new: "bg-too-gold text-voir-blue font-medium shadow-xl",
			active: "bg-green-500 text-white font-medium",
			inactive: "bg-yellow-500 text-white font-medium",
			suspended: "bg-orange-500 text-white font-medium",
			deleted: "bg-red-500 text-white font-medium",
		};

		return (
			colorProfiles[props.bgColorProfile] ||
			"bg-commandes text-white font-medium shadow-xl"
		);
	});

	const pl = computed(() => {
		if (
			props.type === "date" ||
			props.type === "published"
		) {
			return "pl-8";
		}

		return "";
	});
</script>
