<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24.999"
    viewBox="0 0 25 24.999">
    <path
      d="M-3153-2646h-4a4,4,0,0,1-4-4v-4a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v4A4,4,0,0,1-3153-2646Zm1.356-8.751v.018a10.288,10.288,0,0,0-2.366,5.733h.5a9.848,9.848,0,0,1,2.35-5.742v-.423h-3.853v.414Zm-5.84,1.017V-2649h.459v-6.2h-.333c-.11.932-.411,1.09-1.386,1.152v.315Z"
      transform="translate(3161 2658)"
      fill="#6b7980" />
    <path
      d="M-3153.03-2646.03H-3157a4,4,0,0,1-4-4V-2654a4,4,0,0,1,4-4h3.971a4,4,0,0,1,4,4v3.971A4,4,0,0,1-3153.03-2646.03Zm-1.961-4.4a1.589,1.589,0,0,0,1.71,1.521,1.911,1.911,0,0,0,1.43-.567,3.68,3.68,0,0,0,.784-2.6c0-1.448-.234-2.269-.784-2.745a1.916,1.916,0,0,0-1.349-.5,1.932,1.932,0,0,0-1.917,2.07,1.837,1.837,0,0,0,1.908,1.971,1.792,1.792,0,0,0,1.665-1.089h.018a3.618,3.618,0,0,1-.693,2.637,1.429,1.429,0,0,1-1.072.414,1.168,1.168,0,0,1-1.223-1.116Zm-2.691-3.33v4.734h.459v-6.2h-.333c-.11.932-.411,1.091-1.386,1.152v.315Zm4.482,2.088a1.421,1.421,0,0,1-1.44-1.584,1.5,1.5,0,0,1,1.458-1.665,1.492,1.492,0,0,1,1.494,1.647A1.507,1.507,0,0,1-3153.2-2651.676Z"
      transform="translate(3161 2671.029)"
      fill="#3a4d56" />
    <path
      d="M-3152-2646.03h-3.971a4,4,0,0,1-4-4V-2654a4,4,0,0,1,4-4H-3152a4,4,0,0,1,4,4v3.971A4,4,0,0,1-3152-2646.03Zm.736-9.291c-1.366,0-2.214,1.228-2.214,3.2,0,2.006.824,3.2,2.205,3.2a1.892,1.892,0,0,0,1.639-.866,4.242,4.242,0,0,0,.566-2.338,4.167,4.167,0,0,0-.592-2.358A1.881,1.881,0,0,0-3151.264-2655.321Zm-5.607.405a1.224,1.224,0,0,1,1.314,1.35c0,.924-.531,1.249-1.267,1.7l-.209.129-.044.028a3.029,3.029,0,0,0-1.873,2.681h3.816v-.423h-3.312c.1-.972.849-1.439,1.575-1.89l.165-.1.047-.028c.777-.474,1.58-.963,1.58-2.112a1.684,1.684,0,0,0-1.782-1.737,1.865,1.865,0,0,0-1.944,2.016h.459C-3158.312-2654.343-3157.788-2654.916-3156.871-2654.916Zm5.607,5.6c-1.088,0-1.737-1.046-1.737-2.8,0-1.785.633-2.808,1.737-2.808,1.076,0,1.719,1.05,1.719,2.808C-3149.545-2650.338-3150.171-2649.317-3151.264-2649.317Z"
      transform="translate(3173 2671.029)"
      fill="#071821" />
    <path
      d="M-3153-2646.03h-3.971a4,4,0,0,1-4-4V-2654a4,4,0,0,1,4-4H-3153a4,4,0,0,1,4,4v3.971A4,4,0,0,1-3153-2646.03Zm-.1-9.261a1.627,1.627,0,0,0-1.773,1.575,1.427,1.427,0,0,0,.891,1.359v.027a1.65,1.65,0,0,0-1.17,1.62,1.89,1.89,0,0,0,2.061,1.827,2.264,2.264,0,0,0,1.455-.478,1.7,1.7,0,0,0,.605-1.349,1.718,1.718,0,0,0-1.214-1.638v-.027a1.412,1.412,0,0,0,.918-1.341A1.61,1.61,0,0,0-3153.1-2655.291Zm-4.609,1.557V-2649h.459v-6.2h-.333c-.109.932-.409,1.09-1.386,1.152v.315Zm4.618,4.446a1.429,1.429,0,0,1-1.584-1.422,1.4,1.4,0,0,1,1.557-1.413,1.44,1.44,0,0,1,1.611,1.413A1.444,1.444,0,0,1-3153.087-2649.288Zm-.027-3.231a1.169,1.169,0,0,1-1.288-1.179,1.2,1.2,0,0,1,1.306-1.2,1.2,1.2,0,0,1,1.305,1.2A1.213,1.213,0,0,1-3153.114-2652.519Z"
      transform="translate(3174 2658)"
      fill="#093f5a" />
  </svg>
</template>
